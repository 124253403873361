import React from "react";
import { CheckIcon } from "@primer/octicons-react";
import YoutubeEmbed from "../../YoutubeEmbed";
import ReactObserver from "../../logging/reactObserver";

export const WhyCompute = (props) => {
  return (
    <ReactObserver title={"WhyCompute"}>
    <div className="d-flex align-items-center min-vh-100">
      <div className="container-fluid">
        <br/>
        <div className="display-2" style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
          Why use Compute?
        </div>
        <br/>
        <div className="row" style={{ fontFamily: "DM Sans", fontWeight: 400 }}>
          <div className="col-sm-6 col-12 mt-3">
            <CheckRow text="Industry Standard Simulation Engines" />
            <CheckRow text="OpenFOAM" indent={5} />
            <CheckRow text="Radiance" indent={5} />
            <CheckRow text="View progress in real time" />
            <CheckRow text="Edit, delete and download all your files" />
            <CheckRow text="Visualize results in your existing pipeline" />
            <CheckRow text="Setup cases in your favorite CAD tool" />
            <CheckRow text="Grasshopper" indent={5} />
            <CheckRow text="Blender" indent={5} />
            <CheckRow text="Revit" indent={5} />
          </div>
          <div className="col-sm-6 col-12 mt-3 mb-5">
            <YoutubeEmbed
              link="https://www.youtube.com/embed/AWNaoEVGvac?autoplay=1&loop=1"
            />
          </div>
        </div>
      </div>
    </div>
    </ReactObserver>
  );
};


export const CheckRow = props => {
  let { text, indent = 3 } = props;

  return (
    <div className={`ml-${indent} row`}>
      <div className="col-1" style={{ color: "green" }}><CheckIcon /></div>
      <div className={`col-10 h4`}>{text}</div>
    </div>
  );
};