import React from "react";
import { logger } from "../../logging/logger";

export const SignInAndSignUp = (props) => {
  return (
    <article className="row" style={{ fontFamily: "DM Sans" }}>
      <div className="col-sm-6 mt-2">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Login</h3>
            <p className="card-text mt-2" style={{ height: "4rem" }}>
              Click to login to Compute
            </p>
            <a href={"https://compute.procedural.build/accounts/profile/"} className="btn btn-outline-primary"
               onClick={() => {
                 logger.info("Click Log In - Card");
                 return true;
               }}
            >
              Login
            </a>
          </div>
        </div>
      </div>

      <div className="col-sm-6 mt-2">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Sign Up</h3>
            <p className="card-text mt-2" style={{ height: "4rem" }}>
              Are you ready to start you journey with Compute?
            </p>
            <a href={"https://compute.procedural.build/accounts/register/"}
               className="btn btn-outline-primary"
               onClick={() => {
                 logger.info("Click Sign Up");
                 return true;
               }}
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};


export const SignUp = props => {
  let {reference, className} = props;

  return (
    <div className={className}>
      <h4>
        Are you ready to start you journey with Compute?
      </h4>
      <a href={"https://compute.procedural.build/accounts/register/"}
         className="btn btn-outline-primary mt-3"
         onClick={() => {
           logger.info(`Click Sign Up - ${reference}`);
           return true;
         }}
      >
        Sign Up
      </a>
    </div>
  );
};