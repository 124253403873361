import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Logo = () => {
  return (
    <div className="sticky-top d-flex justify-content-end" style={{ zIndex: 900 }}>
      <StaticImage
        className="mt-4 mr-3"
        src="../../../assets/images/landing/procedural_name_grey.png"
        style={{width: "9rem"}}
        alt="procedural logo"
      />
    </div>
  );
};
