import React from "react";
import ReactObserver from "../../logging/reactObserver";
import { logger } from "../../logging/logger";
import { ChevronDownIcon } from "@primer/octicons-react";
import { Link } from "react-scroll";

export const Title = (props) => {
  return (
    <ReactObserver title={"Title"}>
      <div className="d-flex align-items-center min-vh-100">
        <div>
          <div className={`text-white display-2`} style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
            Run your simulation in the cloud.
          </div>
          <a className={`text-primary display-2`} style={{ fontFamily: "DM Sans", fontWeight: 700 }} onClick={() => {
            logger.info("Click - Try Compute");
            return true;
          }}
             href={"https://compute.procedural.build/accounts/register/?front"}>
            <u>Try Compute.</u>
          </a>
          <br />
          <div className="text-center mt-5 text-white" style={{ cursor: "pointer" }}>
            <Link to="signup" smooth duration={1000}>
              <ChevronDownIcon size={64} />
            </Link>
          </div>
        </div>
      </div>
    </ReactObserver>
  );
};
