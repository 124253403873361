import React from "react";
import grasshopperImage from "../../../assets/images/landing/grasshopper_grey_logo.png";
import blenderImage from "../../../assets/images/landing/blender_grey_logo.png";
import jupyterImage from "../../../assets/images/landing/jupyter_grey_logo.png";
import ReactObserver from "../../logging/reactObserver";
import { logger } from "../../logging/logger";

export const Plugins = (props) => {
  return (
    <ReactObserver title={"Plugins"}>
      <article className="d-flex align-items-center min-vh-100" style={{ fontFamily: "DM Sans" }}>
        <div className="container-fluid">
          <br />
          <div className={`mb-2 display-2`} style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
            Plugins
          </div>
          <div className="h4">
            <div className="mb-3">
              Procedural Compute has a comprehensive API which allows you to carry out all of the same actions possible
              through this Web Interface using a REST API - which permits access from Grasshopper, Revit or any other
              client
              which permits scripting.
            </div>
            <div>
              Procedural Compute has already number of plugins integrated into popular CAD software. Follow the
              instructions
              below to download and install.
            </div>
          </div>

          <div style={{ height: "3.0rem" }} />

          <div className="row">
            <PluginCard
              image={grasshopperImage}
              imageAlt="Grasshopper3d logo"
              title="Grasshopper"
              text="Download the Compute Grasshopper plugin by clicking the button below. Follow the instructions on Food4Rhino."
              link="https://food4rhino.com/app/proceduralcs"
            />
            <PluginCard
              image={blenderImage}
              imageAlt="Blender logo"
              title="Blender"
              text="Download the Compute Blender plugin by clicking the button below."
              link="https://github.com/procedural-build/procedural-blender"
            />
            <PluginCard
              image={jupyterImage}
              imageAlt="Jupyter logo"
              title="Jupyter"
              text="Download the Compute Jupyter Notebook plugin by clicking the button below."
              link="https://github.com/procedural-build/procedural-py"
            />
          </div>
          <br />
        </div>
      </article>
    </ReactObserver>
  );
};


export const PluginCard = props => {
  let { image, imageAlt, text, link, linkText = "Download", title } = props;

  return (
    <div className="col-sm-4 mt-2">
      <div className="card">
        <div style={{ height: "12rem" }} className="d-flex align-items-center justify-content-center">
          <img
            src={image}
            className="card-img-top"
            style={{ width: "auto", height: "100%" }}
            alt={imageAlt}
          />
        </div>
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <div className="card-text mt-2" style={{ height: "8rem" }}>
            {text}
          </div>
          <a href={link} className="btn btn-outline-primary" target="_blank" rel="noreferrer" onClick={() => {logger.info("Click " + linkText + " " + title); return true}}>
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};