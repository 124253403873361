import React, { useRef, useEffect } from "react";
import { logger } from "./logger";

const ReactObserver = ({ children, title }) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          logger.info(`Displaying ${title}`);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.2
      }
    );

    if (ref && ref.current) {
      observer.observe(ref.current);
    }
  }, []);
  return <div ref={ref}>{children}</div>;
};

export default ReactObserver;
