import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ReactObserver from "../../logging/reactObserver";
import { logger } from "../../logging/logger";
import { SignUp } from "./signin";

export const Tutorials = (props) => {
  return (
    <ReactObserver title={"Tutorials"}>
      <article className="d-flex align-items-center min-vh-100" style={{ fontFamily: "DM Sans" }}>
        <div className="container-fluid">
          <br />
          <div className={`mb-2 display-2`} style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
            Tutorials
          </div>
          <div className={`h4 row`}>
            <div className="col-sm-6 mt-2">
              <div className="mb-3">
                We provide a collection tutorials for our users to get started and to demonstrate how to use
                Compute&apos;s
                different workflows.
              </div>
              <Link to="/tutorials/" className="btn btn-outline-primary" onClick={() => {logger.info("Click Tutorials"); return true}}>
                See our Tutorials
              </Link>
            </div>
            <div className="col-sm-6 mt-2">
              <StaticImage src="../../../tutorials/AnnualDaylight/annual_daylight.png" alt="annual daylight simulation" className="img-fluid" />
            </div>
          </div>
          <br />
          <SignUp reference={"tutorials"} className="mt-5"/>
        </div>
      </article>
    </ReactObserver>
  );
};
