import React from "react";

export const ClientQuote = (props) => {
  return (
    <div>
      <div className="h4 ml-5 text-secondary" style={{ fontFamily: "DM Sans" }}>
        <div>
          "We ran a project a month ago on our old computer and it took us 24 hours for one wind direction. With
          Procedural Compute, the same task can be done on 4 wind directions running parallel in 4-5 hours.
        </div>
        <div className="mt-1">
          That is a 5 times speedup on one direction, but we get 4 directions out of Procedural Compute, so in total
          around a 20 times speedup!"
        </div>
      </div>
      <div className="float-right">
        <b>Mathias Sønderskov Schaltz</b>
        <div>Computational Design Lead, <a href="https://henninglarsen.com/" className="text-dark">Henning Larsen Architects</a></div>
      </div>
    </div>
  );
};