import React from "react";

export const Description = (props) => {

  return (
      <article className="mt-5 pt-5">
        <div className="display-2" style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
          Compute
        </div>
        <div className={`mb-5 h4`} style={{ fontFamily: "DM Sans", fontWeight: 700 }}>
          Building Physics Task Manager
        </div>
        <div className="h4" style={{ fontFamily: "DM Sans" }}>
          <div className="mb-3">
            Welcome to the Procedural Compute Web UI. This interface allows you to start, stop, monitor and manage
            simulation tasks submitted to Procedural Compute.
          </div>
          <div className="mb-3">
            Evaluating the performance of a design option is crucial to integrating environmental and sustainable
            engineering into early-stage design.
          </div>
          <div className="mb-3">Compute&apos;s focus is to increase the number and quality you can do in a day.</div>
        </div>
      </article>
  );
};