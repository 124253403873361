import React from "react";
import YoutubeEmbed from "../../YoutubeEmbed";
import { CheckRow } from "./why";
import ReactObserver from "../../logging/reactObserver";

export const Workflows = (props) => {
  return (
    <ReactObserver title={"Workflows"}>
      <article className="d-flex align-items-center min-vh-100" style={{ fontFamily: "DM Sans" }}>
        <div className="container-fluid">
          <br />
          <div className="display-2" style={{ fontWeight: 700 }}>
            Workflows
          </div>
          <div className="h3">
            <div>Here you can find an overview of Compute&apos;s workflows</div>
          </div>
          <div style={{ height: "3.0rem" }} />
          <div className="row">
            <div className="col-sm-6 col-12 mt-3">
              <CheckRow text="Custom CFD Workflows" />
              <CheckRow text="Virtual Wind Tunnel" indent={5} />
              <CheckRow text="Pedestrian Wind Comfort" indent={5} />
              <CheckRow text="Wind Analyses with Vegetation" indent={5} />
              <CheckRow text="Daylight and Radiation Workflows" />
              <CheckRow text="Annual Hourly Daylight" indent={5} />
              <CheckRow text="DA, UDI, cDA, sDA, etc." indent={5} />
              <CheckRow text="Annual Solar Radiation" indent={5} />
              <CheckRow text="Daylight Factor" indent={5} />
              <CheckRow text="Sky View Factor" indent={5} />
            </div>
            <div className="col-sm-6 col-12 mt-3 mb-5">
              <YoutubeEmbed link="https://www.youtube.com/embed/w74Q4ozqwnw?autoplay=1&loop=1" />
            </div>
          </div>
          <br />
        </div>
      </article>
    </ReactObserver>
  );
};
