import React from "react";
import swaggerImage from "../../../assets/images/landing/swagger-grey-logo.png";
import repoImage from "../../../assets/images/landing/repo.png";
import peopleImage from "../../../assets/images/landing/people.png";
import { PluginCard } from "./plugins";
import ReactObserver from "../../logging/reactObserver";

export const Documentation = (props) => {
  return (
    <ReactObserver title={"Documentation"}>
      <article className="d-flex align-items-center min-vh-100" style={{ fontFamily: "DM Sans" }}>
        <div className="container-fluid">
          <br />
          <div className="display-2" style={{ fontWeight: 700 }}>
            API and Documentation
          </div>
          <div className="h4">
            <div className="mb-3">Documentation is everything!</div>
            <div>Here you can find documentation for our API, Compute Commands and a link to our Forum.</div>
          </div>

          <div style={{ height: "3.0rem" }} />

          <div className="row">
            <PluginCard
              title="Swagger"
              image={swaggerImage}
              imageAlt="swagger image"
              text="Documentation for the API (fully interactive in OpenAPI v2 format) is available here"
              link="https://compute.procedural.build/api-docs/swagger/"
              linkText="API Docs"
            />
            <PluginCard
              title="Documentation"
              image={repoImage}
              imageAlt="documentation image"
              text="Curious about all the commands you can do in Compute? Browse our Compute Commands documentation."
              link="/docs/"
              linkText="Read Documentation"
            />
            <PluginCard
              title="Discourse"
              image={peopleImage}
              imageAlt="discourse image"
              text="Stuck at a problem that you need help with? Go to our forum and ask our friendly community for help."
              link="https://discourse.procedural.build/"
              linkText="Visit Forum"
            />
          </div>
          <br />
        </div>
      </article>
    </ReactObserver>
  );
};