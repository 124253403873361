import React from "react";
import Header from "../components/header";
import { Logo } from "../components/landingPage/logo";
import { Title } from "../components/landingPage/title";
import { WhyCompute } from "../components/landingPage/why";
import { Description } from "../components/landingPage/description";
import { SignInAndSignUp, SignUp } from "../components/landingPage/signin";
import { Plugins } from "../components/landingPage/plugins";
import { Workflows } from "../components/landingPage/workflows";
import { Pricing } from "../components/landingPage/pricing";
import { Tutorials } from "../components/landingPage/tutorials";
import { Documentation } from "../components/landingPage/documentation";
import { Footer } from "../components/footer";
import Helmet from "react-helmet";
import favicon from "../../assets/favicon/favicon.ico";
import appleIcon from "../../assets/favicon/apple-touch-icon.png";
import favicon32 from "../../assets/favicon/favicon-32x32.png";
import favicon16 from "../../assets/favicon/favicon-16x16.png";
import metaImage from "../../assets/images/landing/procedural_logoname_#D3D3D3_200x100.png";
import ReactObserver from "../logging/reactObserver";
import { ClientQuote } from "../components/landingPage/clientQuote";

const Index = () => {
  let metaTitle = "Compute | Cloud Building Physics";

  let metaDescription = "Compute orchestrates and scales building physics simulation tasks in the cloud, such as CFD and Radiance.";

  let canonicalUrl = "https://compute.procedural.build";

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="image" property="og:image" content={`https://compute.procedural.build${metaImage}`} />
        <meta name="twitter:image" content={`https://compute.procedural.build${metaImage}`} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta name="twitter:site" content="@ApsProcedural" />
        <meta name="twitter:creator" content="@ApsProcedural" />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="icon" type="image/png" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Procedural",
            "url": "https://procedural.build",
            "logo": "https://compute.procedural.build${metaImage}"
          }`}
        </script>
      </Helmet>
      <Header search={false} openInTab={false} />
      <div className="bg-dark">
        <Logo />

        <div className="container">
          <Title />
        </div>

        <div className="bg-white" id="signup">
          <hr />
          <div className="container d-flex align-items-center min-vh-100">
            <ReactObserver title={"Description & Quote"}>
              <div className="container-fluid">
                <br />
                <Description />
                <br />
                <ClientQuote />
                <br />
                <SignUp reference={"client-quote"} className="mt-5"/>
              </div>
            </ReactObserver>
          </div>
        </div>

        <div className="bg-white" id="tutorials">
          <hr />
          <div className="container">
            <Tutorials />
          </div>
        </div>

        <div className="bg-white" id="clients">
          <hr/>
          <div className="container">
            <WhyCompute />
          </div>
        </div>

        <div className="min-vh-100 bg-white" id="pricing">
          <hr />
          <div className="container">
            <Pricing />
          </div>
        </div>

        <div className="min-vh-100 bg-white" id="clients">
          <hr />
          <div className="container">
            <Plugins />
          </div>
        </div>

        <div className="min-vh-100 bg-white" id="clients">
          <hr />
          <div className="container">
            <Workflows />
          </div>
        </div>

        <div className="min-vh-100 bg-white" id="docs">
          <hr />
          <div className="container">
            <Documentation />
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Index;