import React from "react";
import { CheckIcon } from "@primer/octicons-react";
import cloudImage from "../../../assets/images/landing/cloud.png";
import databaseImage from "../../../assets/images/landing/database.png";
import toolsImage from "../../../assets/images/landing/tools.png";
import ReactObserver from "../../logging/reactObserver";
import { logger } from "../../logging/logger";
import { Link } from "gatsby";

export const Pricing = (props) => {

  return (
    <ReactObserver title={"Pricing"}>
      <article className="d-flex align-items-center min-vh-100" style={{ fontFamily: "DM Sans" }}>
        <div className="container-fluid">
          <br />
          <div className="display-2" style={{ fontWeight: 700 }}>
            Pricing
          </div>
          <div style={{ height: "3.0rem" }} />

          <div className="row">
            <PriceCard
              title="Cloud"
              image={cloudImage}
              imageAlt="cloud price model"
              rows={[
                "No Setup Costs", "0.6€/CPU-Hour",
                "Unlimited Number of CPUs",
                "Unlimited Projects",
                "Unlimited Company Members",
                "Open-Source Plugins",
                "Free Demos Available"
              ]}
              link="/accounts/register/?pricing"
              linkText="Get started for free!"
            />
            <PriceCard
              title="On-Premise"
              image={databaseImage}
              imageAlt="onPrem price model"
              rows={[
                "Everything from Cloud",
                "Runs on your hardware",
                "Unlimited CPU-Hours",
                "One time Setup Cost",
                "Flat monthly fee",
              ]}
              link="mailto:mark@procedural.build"
              linkText="Get a Quote!"
            />
            <PriceCard
              title="Support & Development"
              image={toolsImage}
              imageAlt="support and development price model"
              rows={[
                "Project Support",
                "Building Physics Support",
                "Custom Grasshopper Scripts",
                "Integrate with Compute"
              ]}
              link="mailto:mark@procedural.build"
              linkText="Contact Us!"
            />
          </div>
          <br />
          <div className="mt-3">
            <h3>Price Examples</h3>
            <div>The simulation costs vary from case to case. At our documentation page we have listed price examples for various case scenarios.</div>
            <Link to="docs/costs/cfd" className="btn btn-outline-primary mt-3" onClick={() => {
              logger.info(`Click see price example`);
              return true;
            }}>
              See price examples
            </Link>
          </div>
          <br />
        </div>
      </article>
    </ReactObserver>
  );
};


const CheckRow = props => {
  let { text } = props;

  return (
    <div className={`row`}>
      <div className="col-2" style={{ color: "green" }}><CheckIcon /></div>
      <div>{text}</div>
    </div>
  );
};

const PriceCard = props => {
  let { image, imageAlt, title, rows, link, linkText } = props;

  return (
    <div className="col-sm-4 mt-2">
      <div className="card">
        <div style={{ height: "12rem" }} className="d-flex align-items-center justify-content-center">
          <img
            src={image}
            alt={imageAlt}
            className="card-img-top"
            style={{ width: "auto", height: "80%", display: "block" }}
          />
        </div>
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <div className="card-text mt-2 mb-2" style={{ height: "12rem" }}>
            {rows.map((row, key) => <CheckRow key={key} text={row} />)}
          </div>
          <a href={link} target="_blank"
             rel="noreferrer"
             className="btn btn-outline-primary"
             onClick={() => {
               logger.info(`Click - ${linkText}`);
               return true;
             }}
          >
            {linkText}
          </a>
        </div>
      </div>
    </div>
  );
};

